












































































import {
  Vue, Component, Prop, Watch 
} from 'vue-property-decorator';

import Select from '@/components/Input/Select/Select.vue';

import SimulationService from '@/services/Simulation/SimulationService';

import {
  FIRST_COURSE_ID,
  SECOND_COURSE_ID,
  THIRD_COURSE_ID,
  LIST_REGION,
  LIST_STATE,
  LIST_VACANCY,
  MESSAGE_REQUIRED_COURSE,
  MESSAGE_REQUIRED_TYPE_VACANCY
} from './constants/SelectsOptions';

import {
  Course,
  CourseRequest,
  Region,
  State,
  Vacancy,
  SelectOptions
} from './interface/ISelectOptions';

@Component({
  components: {
    Select
  }
})
export default class SelectChoice extends Vue {
  @Prop({ default: false }) isSetValidation!: boolean;

  private listCourse: Array<Course> | [] = [];
  private listRegion = LIST_REGION;
  private listState = LIST_STATE;
  private listVacancy = LIST_VACANCY;

  private selectCourses: Array<Course> | any = [];
  private selectRegion: Region | null = null;
  private selectState: State | null = null;
  private selectVacancy: Vacancy | null = null;

  private listCourseSelectOneOption: Array<Course> | [] = [];
  private listCourseSelectTwoOption: Array<Course> | [] = [];
  private listCourseSelectThreeOption: Array<Course> | [] = [];

  private messageRequiredCourse = MESSAGE_REQUIRED_COURSE;
  private messageRequiredVacancy = MESSAGE_REQUIRED_TYPE_VACANCY;

  private SimulationService = new SimulationService();

  created() {
    this.getListCourse();
  }

  get listRegionOrder() {
    return this.listRegion.sort((regionFirst: Region, regionSecond: Region) => {
      if (regionFirst.title < regionSecond.title) return -1;
      if (regionFirst.title > regionSecond.title) return 1;
      return 0;
    });
  }

  get listStateForRegion() {
    return this.listState
      .filter((state: State) => state.region?.value === this.selectRegion?.value)
      .sort((stateFirst: State, stateSecond: State) => {
        if (stateFirst.title < stateSecond.title) return -1;
        if (stateFirst.title > stateSecond.title) return 1;
        return 0;
      });
  }

  get showFeedbackCourse() {
    return this.isSetValidation && !this.selectCourses[FIRST_COURSE_ID];
  }

  get showFeedbackVacancy() {
    return this.isSetValidation && !this.selectVacancy;
  }

  get listSortedVacancyTitle() {
    return this.listVacancy.sort((vacancyFirst: Vacancy, vacancySecond: Vacancy) => {
      if (vacancyFirst.title < vacancySecond.title) return -1;
      if (vacancyFirst.title > vacancySecond.title) return 1;
      return 0;
    });
  }

  async getListCourse() {
    try {
      const response: Array<CourseRequest> = await this.SimulationService.getListCourseSimulator();

      this.listCourse = this.treatmentListCourse(response);
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar a lista de cursos.',
        status: 'error'
      });
      console.error(error);
    }
  }

  treatmentListCourse(listCourse: Array<CourseRequest>) {
    return listCourse.reduce(
      (prevCourse: Array<Course>, nextCourse: CourseRequest) => [
        ...prevCourse,
        {
          degree: nextCourse.degree,
          value: nextCourse.id,
          title: nextCourse.name
        }
      ],
      []
    );
  }

  @Watch('listCourse')
  setListsCourse() {
    this.listCourseSelectOneOption = this.listCourse;
    this.listCourseSelectTwoOption = this.listCourse;
    this.listCourseSelectThreeOption = this.listCourse;
  }

  @Watch('selectCourses', { deep: true })
  @Watch('selectRegion', { deep: true })
  @Watch('selectState', { deep: true })
  @Watch('selectVacancy', { deep: true })
  emitSelectionOptions() {
    this.$emit('selects-options', this.createSelectOptions());
    this.$emit('validation-selects', this.validationForm());
  }

  setSelectCourseFirst(selected: number) {
    const foundCourse = this.listCourse.find((course: Course) => course.value === selected);

    if (foundCourse) {
      this.$set(this.selectCourses, FIRST_COURSE_ID, foundCourse);
      this.clearListCourseTwoOption();
      this.clearListCourseThreeOption();
    }
  }

  setSelectCourseSecond(selected: number) {
    const foundCourse = this.listCourse.find((course: Course) => course.value === selected);

    if (foundCourse) {
      this.$set(this.selectCourses, SECOND_COURSE_ID, foundCourse);
      this.clearListCourseOneOption();
      this.clearListCourseThreeOption();
    }
  }

  setSelectCourseThird(selected: number) {
    const foundCourse = this.listCourse.find((course: Course) => course.value === selected);

    if (foundCourse) {
      this.$set(this.selectCourses, THIRD_COURSE_ID, foundCourse);
      this.clearListCourseOneOption();
      this.clearListCourseTwoOption();
    }
  }

  clearListCourseOneOption() {
    const selectCourse = [
      this.selectCourses[SECOND_COURSE_ID],
      this.selectCourses[THIRD_COURSE_ID]
    ];
    this.listCourseSelectOneOption = this.listCourse.filter(
      (course: Course) => !selectCourse.includes(course)
    );
  }

  clearListCourseTwoOption() {
    const selectCourse = [this.selectCourses[FIRST_COURSE_ID], this.selectCourses[THIRD_COURSE_ID]];
    this.listCourseSelectTwoOption = this.listCourse.filter(
      (course: Course) => !selectCourse.includes(course)
    );
  }

  clearListCourseThreeOption() {
    const selectCourse = [
      this.selectCourses[FIRST_COURSE_ID],
      this.selectCourses[SECOND_COURSE_ID]
    ];
    this.listCourseSelectThreeOption = this.listCourse.filter(
      (course: Course) => !selectCourse.includes(course)
    );
  }

  setSelectRegion(selected: string) {
    const foundRegion = this.listRegion.find((region: Region) => region.value === selected);

    this.selectRegion = foundRegion || null;
  }

  setSelectState(selected: string) {
    const foundState = this.listStateForRegion.find((state: Region) => state.value === selected);

    this.selectState = foundState || null;
  }

  setSelectVacancy(selected: string) {
    const foundVacancy = this.listVacancy.find((vacancy: Vacancy) => vacancy.value === selected);

    this.selectVacancy = foundVacancy || null;
  }

  validationForm() {
    return this.selectCourses[FIRST_COURSE_ID] && this.selectVacancy;
  }

  createSelectOptions(): SelectOptions {
    return {
      courses: this.selectCourses,
      region: this.selectRegion,
      state: this.selectState,
      vacancy: this.selectVacancy
    };
  }
}
